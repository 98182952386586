import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../dashboard/Header";

const DailyReportTable = () => {
  const [reports, setReports] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [filteredReports, setFilteredReports] = useState([]);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(
          "https://api.indura.hemsidaavt.com/api/revenue"
        );
        setReports(response.data);
      } catch (err) {
        setError("Failed to fetch reports.");
      }
    };
    fetchReports();
  }, []);

  // Filter reports by month when selectedMonth changes
  useEffect(() => {
    if (selectedMonth) {
      const filtered = reports.filter((report) =>
        new Date(report.date).toISOString().startsWith(selectedMonth)
      );
      setFilteredReports(filtered);
    } else {
      setFilteredReports(reports);
    }
  }, [selectedMonth, reports]);

  // Handle month selection
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <>
      <Header />
      <div className="p-8 bg-gray-100 min-h-screen">
        <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-6">
          <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Daily Financial Reports
          </h1>

          {/* Error Handling */}
          {error && (
            <div className="text-red-600 bg-red-100 border border-red-300 rounded-lg p-4 mb-4">
              {error}
            </div>
          )}

          {/* Month Filter */}
          <div className="mb-6 flex flex-col md:flex-row md:items-center justify-between">
            <label
              htmlFor="month"
              className="block text-lg font-semibold text-gray-700 mb-2 md:mb-0"
            >
              Filter by Month:
            </label>
            <input
              type="month"
              id="month"
              className="border border-gray-300 rounded-lg p-2 w-full md:w-1/3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              onChange={handleMonthChange}
              value={selectedMonth}
            />
          </div>

          {/* Reports Table */}
          <div className="overflow-x-auto">
            <table className="w-full bg-white border border-gray-200 rounded-lg shadow-md">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="px-6 py-3 text-left font-semibold text-sm uppercase">
                    Date
                  </th>
                  <th className="px-6 py-3 text-center font-semibold text-sm uppercase">
                    Expenditure
                  </th>
                  <th className="px-6 py-3 text-center font-semibold text-sm uppercase">
                    Revenue
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.length > 0 ? (
                  filteredReports.map((report, index) => (
                    <tr
                      key={report.id}
                      className={`${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      } border-b hover:bg-gray-100`}
                    >
                      <td className="px-6 py-4 text-gray-800">
                        {new Date(report.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 text-center text-gray-800">
                        ₹{parseFloat(report.expenditure).toFixed(2)}
                      </td>
                      <td className="px-6 py-4 text-center text-gray-800">
                        ₹{parseFloat(report.revenue).toFixed(2)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      No data available for the selected month.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyReportTable;
